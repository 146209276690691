@for $col from 1 through $max-cols {
    .col-start-#{$col} {
        grid-column-start: $col
    }
}

@for $col from 1 through $max-cols {
    .col-end-#{$col} {
        grid-column-end: $col
    }
}

@for $row from 1 through $max-rows {
    .row-start-#{$row} {
        grid-row-start: $row
    }
}

@for $row from 1 through $max-rows {
    .row-end-#{$row} {
        grid-row-end: $row
    }
}

.col-span-full {
    grid-column: 1 / -1;
}

.checkbox-container {
    width: 32px;
}

.font-size-sm {
    font-size: 12px;
}

.min-w-25 {
    min-width: 25%;
}

.min-w-1\/3 {
    min-width: 33.33%;
}

.min-w-50 {
    min-width: 50%;
}

.min-w-2\/3 {
    min-width: 66.67%;
}

.min-w-75 {
    min-width: 75%;
}

.min-w-90 {
    min-width: 90%;
}

.min-w-100 {
    min-width: 100%;
}

.max-w-25 {
    max-width: 25%;
}

.max-w-1\/3 {
    max-width: 33.33%;
}

.max-w-50 {
    max-width: 50%;
}

.max-w-2\/3 {
    max-width: 66.67%;
}

.max-w-75 {
    max-width: 75%;
}

.max-w-100 {
    max-width: 100%;
}

.h-1\/3 {
    height: 33.33%;
}

.h-2\/3 {
    height: 66.67%;
}

// No bootstrap util for justify-self 
.justify-self-auto     { justify-self: auto !important; } 
.justify-self-start    { justify-self: flex-start !important; } 
.justify-self-end      { justify-self: flex-end !important; } 
.justify-self-center   { justify-self: center !important; } 
.justify-self-baseline { justify-self: baseline !important; } 
.justify-self-stretch  { justify-self: stretch !important; } 