// --------------------------------------------
// Tabs
// Style overrides for "react-tabs-scrollable" to make them
// match the overall design of WealthPro 3. Note, we do not
// style the colours here and use "styled-components" within
// the main source of the tabs component to get our theme
// colours.
//
//  https://react-tabs-scrollable.vercel.app/demos
//
// --------------------------------------------
.rts___tabs {
    padding: 0;
}

.rts___tab {
    margin: 0;
    padding: 0.5rem 1rem;
    position: relative;
}

.rts___nav___btn svg {
    max-width: unset;
}
  
.rts___btn {
    border: 1px solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}
  
.rts___tab::after {
    content: "";
    margin: auto;
    height: 3px;
    background: transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
    width: 0;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
}

.rts___tab___selected {
    position: relative;
    width: 100%;
    background: transparent;
    box-shadow: none;
    transition: background-color 0.5s ease;
}

.rts___tabs___container > .rts___nav___btn___container:first-child {
    margin-right: .5rem;
}

.rts___tabs___container > .rts___nav___btn___container:last-child {
    margin-left: .5rem;
}

.rts___nav___btn:hover {
    background-color: unset;
}

.rts___nav___btn:hover > svg {
    stroke: rgba(0, 0, 0, 0.6);
}
  
@media (max-width: 991.98px) {
    .rts___tabs___container {
        padding: 0;
    }
}

@media (max-width: 767.98px) {
    .rts___tab {
        padding: 5px;
    }
}