@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('react-big-calendar/lib/css/react-big-calendar.css');
@import "../../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../../node_modules/react-loading-skeleton/dist/skeleton.css";
@import "../../node_modules/react-tabs-scrollable/dist/rts.css";
@import "../../node_modules/react-confirm-alert/src/react-confirm-alert.css";
@import "../../node_modules/tippy.js/dist/tippy.css";
@import "base";
@import "colours";
@import "layout";
@import "tables";
@import "tabs";
@import "chat";

// * {
//     outline: 1px solid #f00 !important;
//   }

.form-check-label {
    margin-bottom: 0;
    margin-top: 3px;
}

.table-action-icon-column {
    width: 32px;
}

.toast-footer {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.toast-footer > button:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.react-confirm-alert-overlay {
    background-color: #000 !important;
    opacity: 0 !important;
}

// TODO: move these to their own files?
// login component
.login-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-card {
    min-width: 60%;
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
}

.login-card.login-error {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
}

.login-info-panel-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background-image: url('/images/login-bg-1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.login-info-panel-contents {
    z-index: 1;
    position: relative;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.blink {
    animation: blink 4s infinite;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

.pulse-sm {
    animation: pulse-animation-sm 2s infinite;
}

.pulse-xs {
    animation: pulse-animation-xs 2s infinite;
}


.pulse-round {
    border-radius: 50%;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-sm {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-xs {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
    }
}

@keyframes blink {
    0%, 50%, 100% {
        opacity: 1;
    }
    25%, 75% {
        opacity: 0.3;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

@keyframes ellipsis-animation {
    0%  { clip-path: inset(0 100% 0 0); }
    25% { clip-path: inset(0 66.6% 0 0); }
    50% { clip-path: inset(0 33.3% 0 0); }
    75% { clip-path: inset(0 0 0 0); }
}

// login component - end