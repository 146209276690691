/*
    All CSS classes required to display the messages as chat bubbles
*/
$bgSender: $primary;
$textSender: white;
$bgReceiver: $gray-300;
$textReceiver: $gray-800;

.chat {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    column-gap: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.chat-error-parent {
    max-width: 50%;
    margin: auto;
}

.chat-reloader {
    position: absolute;
    top: 0;
    right: 15px;
    cursor: pointer;
    z-index: 20;
}

.chat-fetching {
    position: absolute;
    bottom: 25px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.chat-bubble {
    position: relative;
    grid-row-start: 2;
    display: flex;
    width: -mox-fit-content;
    width: fit-content;
    max-width: 80%;
    min-height: 2.75rem;
    min-width: 2.75rem;
}

.chat-bubble.hover {
    gap: 0.625rem;
}

.chat-bubble .chat-bubble-content {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.chat-bubble .chat-bubble-content.chat-bubble-receiver {
    color: $textReceiver;
    background-color: $bgReceiver;
    border-bottom-left-radius: 0px;
}

.chat-bubble .chat-bubble-content.chat-bubble-sender {
    color: $textSender;
    background-color: $bgSender;
    border-bottom-right-radius: 0px;
}

.chat-bubble .chat-bubble-content.chat-bubble-loading {
    color: $textReceiver;
    background-color: $bgReceiver;
}

.chat-start {
    place-items: start;
    grid-template-columns: auto 1fr;
}

.chat-start .chat-bubble {
    grid-column-start: 2;
}

.chat-end {
    place-items: end;
    grid-template-columns: 1fr auto;
}

.chat-image {
    grid-row: span 2/span 2;
    align-self: flex-end;
}

.chat-header {
    grid-row-start: 1;
    font-size: .875rem;
    line-height: 1.25rem;
    display: flex;
}

.chat-header > *:not(:last-child) {
    margin-right: .5rem;
}

.chat-header > .chat-header-sender-name {
    color: $gray-800;
}

.chat-header > .chat-header-sent-date {
    color: $gray-600;
    font-size: .725rem;
}

.chat-header .chat-header-recipients-icon {
    color: $gray-800;
}

.chat-header-recipients-inner {
    text-align: left;
    position: relative;
    z-index: 10000 !important;
}

.chat-header-recipients-inner > .tooltip-inner {
    text-align: inherit;
}

.chat-footer {
    grid-row-start: 3;
    font-size: .875rem;
    line-height: 1.25rem;
}

.chat-start .chat-image {
    grid-column-start: 1;
}

.chat-end .chat-image {
    grid-column-start: 2;
}

.chat-start .chat-header,
.chat-start .chat-footer {
    grid-column-start: 2;
}

.chat-end .chat-header,
.chat-end .chat-footer {
    grid-column-start: 1;
}

.chat-options {
    display: none;
    flex-direction: row;
}

.chat-start .chat-bubble .chat-options {
    position: absolute;
    right: -2.75rem;
    top: calc(50% - 20px);
}

.chat-end .chat-bubble .chat-options {
    position: absolute;
    left: -2.75rem;
    top: calc(50% - 20px);
}