.bg-deadline-due, .bg-priority-medium, .bg-todo, .bg-access-limited {
    background-color: #fffec1;
}

.bg-deadline-future, .bg-priority-low, .bg-info-only {
    background-color: $gray-300;
}

.bg-deadline-soon, .bg-complete, .bg-access-allowed, .bg-boolean-pill-true, .bg-access-full {
    background-color: #ddfbe1;
}

.bg-deadline-overdue,  .bg-priority-high, .bg-cancelled, .bg-access-blocked, .bg-boolean-pill-false {
    background-color: #fee2e2;
}

.text-deadline-due, .text-priority-medium, .text-todo, .text-access-limited {
    color: #a66a02;
}

.text-deadline-future, .text-priority-low , .text-info-only {
    color: $gray-600;
}

.text-deadline-soon, .text-complete, .text-access-allowed, .text-boolean-pill-true, .text-access-full {
    color: #2abd44;
}

.text-deadline-overdue, .text-priority-high, .text-cancelled, .text-access-blocked, .text-boolean-pill-false {
    color: #f02c2c;
}