.react-datepicker {
    display: flex !important;
}

.react-datepicker__tab-loop {
    z-index: 3 !important;
}

.Select__menu-portal {
    z-index: 3 !important;
}

.sun-editor .se-wrapper {
    z-index: 0 !important;
}

.sun-editor .se-toolbar {
    z-index: 4 !important
}

.sun-editor, .se-wrapper, .se-wrapper-inner, .se-wrapper-wysiwyg {
    border-radius: .25rem;
}

.anchor {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
}

.anchor:hover {
    color: #0a58ca;
}

.loading-ellipsis {
    font-size: 1.125rem;
}

.loading-ellipsis::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis-animation steps(1,end) 2s infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
}