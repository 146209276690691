// --------------------------------------------
// Tables
// Style for styling the base Bootstrap table components
//
// --------------------------------------------

table.table-wp3 {
    background-color: #fff;
    border-spacing: 0;
}

table.table-wp3 > :not(:first-child) { 
    border-color: var(--bs-gray-300); 
}

td.action-col {
    width: 32px;
}

td.overflow-ellipsis {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

th.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

tfoot.sticky-footer {
    position: sticky;
    bottom: -1px;
    z-index: 1;
    background-color: #fff;
}