// --------------------------------------------
// Base file is used to reset all the default variables
// Note; import bootstrap here and now because we can 
// use the scss variables in our extra classes!
// --------------------------------------------
:root {
    --avatar-size: 32px;
    --navbar-size: 56px;
}

$max-cols: 20;
$max-rows: 50;
$body-bg: #f7f7f7;
$input-bg: white;
$font-size-base: .875rem;
$font-size-xs: .688rem;
$font-family-base: 'Poppins';
$form-label-font-size: .813rem;

@import "../../node_modules/bootstrap/scss/bootstrap";

.cursor-pointer {
    cursor: pointer;
}

.caret-off .dropdown-toggle::before {
    display: none;
}

.caret-off .dropdown-toggle::after {
    display: none;
}

.fs-smaller {
    font-size: $font-size-sm;
}

.fs-smallest {
    font-size: $font-size-xs;
}

.has-pointer {
    cursor: pointer;
}

.no-body-padding .accordion-body {
    padding: 0 !important;
}

.tabled-list .accordion-body > div:not(:last-child) {
    border-bottom: 1px solid $gray-300;
}

.text-gold {
    color: #FFD700;
}

.text-word {
    color: #2166C3;
}

.text-excel {
    color: #148348;
}

.text-code {
    color: #2166C3;
}

.text-pdf {
    color: #FA0F00;
}

.text-lines {
    color: #6FCFEB;
}

.text-image {
    color: #B24BF3;
}

.text-video {
    color: #B24BF3;
}

.text-file {
    color: #6FCFEB;
}